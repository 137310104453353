import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import Layout from '../components/Layout'
import NewLinkSectionDay from '../components/NewLinkSectionDay'
import SignUp from '../components/SignUp'
import classNames from 'classnames'
import getDomain from 'getdomain'
import { ArrowLeft, ArrowRight } from '../components/Icons'
import Seo from '../components/Seo'
import { useScrollTop } from '../utils'
import './newLinksArchive.scss'

const NewLinksArchive = ({
  pageContext: { chunk, hasNext, hasPrev },
  path,
}) => {
  const [stateChunk, setStateChunk] = useState(chunk)

  useEffect(() => {
    const sitesToSendToAzureFunction = chunk.reduce((acc, day) => {
      day.nodes.forEach(node => {
        if (!node.icon || (!node.icon.azureURLs && !node.icon.base64)) {
          acc.push(node.link)
        }
      })

      return acc
    }, [])

    fetch(
      'https://wdricons.azurewebsites.net/api/getIcons?urls=' +
        sitesToSendToAzureFunction.join(',')
    )
      .then(response => response.json())
      .then(data => {
        const newStateChunk = stateChunk.map(day => {
          return {
            ...day,
            nodes: day.nodes.map(node => ({
              ...node,
              icon: {
                ...node.icon,
                azureURLs: data[getDomain.hostname(node.link)],
              },
            })),
          }
        })

        setStateChunk(newStateChunk)
      })
  }, [chunk, stateChunk, setStateChunk])

  useScrollTop()

  return (
    <Layout>
      <Seo title="Archive" />
      <main className="NewLinksArchive">
        <div className="NewLinksArchive_daysWrap">
          {stateChunk.map((day, index) => (
            <>
              <NewLinkSectionDay {...day} isActive isStatic key={day.date} />
              {(index + 4) % 5 === 0 && <SignUp archive />}
            </>
          ))}
        </div>
      </main>
      <nav>
        <div className="NewLinksArchive_paginationWrap">
          <Link
            to={`/new/${Number(path.slice(-1)) - 1}`}
            className={classNames('NewLinksArchive_prev', {
              'NewLinksArchive_prev--visible': hasPrev,
            })}
          >
            <ArrowLeft /> Previous
          </Link>
          <span className="NewLinksArchive_number">
            <span className="NewLinksArchive_number_desktop">
              You are on page {path.slice(-1)}
            </span>
            <span className="NewLinksArchive_number_mobile" aria-hidden>
              Page {path.slice(-1)}
            </span>
          </span>
          <Link
            to={`/new/${Number(path.slice(-1)) + 1}`}
            className={classNames('NewLinksArchive_next', {
              'NewLinksArchive_next--visible': hasNext,
            })}
          >
            Next
            <ArrowRight />
          </Link>
        </div>
      </nav>
    </Layout>
  )
}

export default NewLinksArchive
